import { getAppName } from "utils/validators";

/***********************************************
 * Converte o número do mês (0 a 11) em texto
 ***********************************************/
export const convertMonth = (month) => {
	switch (month) {
		case 0:
			return "Janeiro";
		case 1:
			return "Fevereiro";
		case 2:
			return "Março";
		case 3:
			return "Abril";
		case 4:
			return "Maio";
		case 5:
			return "Junho";
		case 6:
			return "Julho";
		case 7:
			return "Agosto";
		case 8:
			return "Setembro";
		case 9:
			return "Outubro";
		case 10:
			return "Novembro";
		case 11:
			return "Dezembro";
		default:
			return "";
	}
};

/*******************************************************
 * Converte o número do dia da semana (0 a 6) em texto
 *******************************************************/
export const convertDayOfWeek = (day) => {
	switch (day) {
		case 0:
			return "Domingo";
		case 1:
			return "Segunda-feira";
		case 2:
			return "Terça-feira";
		case 3:
			return "Quarta-feira";
		case 4:
			return "Quinta-feira";
		case 5:
			return "Sexta-feira";
		case 6:
			return "Sábado";
		default:
			return "";
	}
};

/**************************************************************
 * Retorna o texto de termos de uso, recebendo a % por parâmetro
 **************************************************************/
export const termsOfUse = (percentage) => {
	const terms = `
Estas regras de uso aplicam-se à utilização do meio de pagamento do aplicativo ${getAppName()} (adiante denominado ${getAppName(true)}) oferecido pela ${getAppName()} Gestão de Negócios Ltda, a qualquer pessoa que atenda todos os requisitos destas regras de uso (adiante denominado “Usuário” ou no plural "Usuários").
O Usuário deverá ler, certificar-se de haver entendido e aceitar todas as condições estabelecidas nestas regras de uso, assim como nos demais documentos incorporados por referência.

1. Objeto

1.1. O ${getAppName()} oferece aos Usuários, por si ou terceiros, o meio de pagamento online (${getAppName(true)}) pelo aplicativo ${getAppName()} Business que processa pagamentos das principais bandeiras de cartão, através da transmissão e processamento das transações.

2. Adesão

2.1. O início do processo de adesão do Usuário ao ${getAppName(true)} ocorre meio de auto credenciamento via aplicativo ${getAppName()} Business, ou ainda, por outros canais que vierem a ser disponibilizados.
  2.1.1. As informações fornecidas pelo Usuário no processo de compra serão consideradas para definição do cadastro principal de credenciamento, assim como o domicílio bancário indicado para o recebimento das transações.
2.2. O efetivo credenciamento e a adesão do Usuário poderá ser condicionado à aceitação prévia do ${getAppName()}, mediante apresentação pelo Usuário, quando necessário, de documentos solicitados, que serão utilizados para análise das atividades por ele desenvolvidas, da sua saúde financeira e de seus sócios/representantes/proprietários/acionistas, dentre outros critérios de análise cadastral e financeira que venham a ser adotados, a qualquer tempo durante a vigência do presente Termo.
2.3. Caso o ${getAppName()} avalie que os critérios de análise cadastral e financeira não foram atingidos ou completos, tais como, informações incorretas, incompletas e/ou inverídicas, não envio de forma satisfatória de documentos e esclarecimentos solicitados, poderão bloquear, suspender, cancelar e/ou revogar o credenciamento do Usuário ou a aprovação e a realização de quaisquer transações ou movimentações, sem prejuízo de outras medidas de acordo com o previsto no presente Termo, e eventual responsabilidade por perdas e danos.
2.4. O Usuário se compromete a não efetuar transações em segmentos ou ramos de atividades diversos daqueles informados pelo Usuário no momento de seu credenciamento, mesmo que tais atividades constem de seu objeto social.
2.5. Determinados Usuários não serão credenciados ou serão descredenciados por exercerem atividades consideradas ilegais ou indesejáveis.
  
3. Domicílio Bancário

3.1. O Usuário indicará no processo de credenciamento um domicílio bancário de mesma titularidade para o recebimento do valor líquido das transações.
  3.1.1. Os valores das transações com o ${getAppName(true)} realizadas por meio de cartão de crédito serão repassados pelo ${getAppName()} por transferência bancária ao domicílio bancário informado na sexta-feira posterior ao prazo D+30 da referida transação. O custo para a transferência dos valores é de R$ 2,00 (dois reais).
  3.1.2. Os valores das transações com o ${getAppName(true)} realizadas por meio de transação pix de sexta até quinta-feira da semana serão repassados pelo ${getAppName()} por transferência bancária ao domicílio bancário informado na sexta-feira subsequente. O custo para transferência dessa modalidade também é de R$ 2,00 (dois reais).
3.2. O Usuário se obriga a zelar pela regularidade do domicílio bancário, como também, pela retificação das informações prestadas ao ${getAppName()}. Caso o banco depositário do domicílio bancário declare-se impedido, por qualquer motivo, de dar cumprimento às ordens de crédito emitidas pelo ${getAppName()}, o usuário ficará encarregado de providenciar a regularização do domicílio bancário ou, ainda, indicar novo domicílio, informando tal providência ao ${getAppName()}, que estarão autorizados a reter o pagamento dos créditos até a regularização do domicílio bancário, sem quaisquer ônus, penalidades ou encargos e à providenciar o bloqueio do equipamento, caso o domicílio bancário não seja regularizado pelo usuário, no prazo concedido pelo ${getAppName()}.
3.3. Em caso de término da relação estabelecida entre a ${getAppName()} e o Usuário por qualquer motivo, o Usuário compromete-se a manter ativo seu domicílio bancário até que todas as transações sejam quitadas, incluindo transações de crédito parceladas.
3.4. Caso o banco depositário do domicílio bancário se declare impedido, por qualquer motivo, de dar cumprimento às ordens de crédito emitidas pelo ${getAppName()}, deverá o Usuário providenciar a regularização do domicílio bancário ou ainda, indicar novo domicílio bancário. Neste caso, o Usuário deverá informar tal providência ao ${getAppName()}, que estarão autorizados a reter o pagamento dos créditos até o recebimento do pedido de mudança e regularização do domicílio bancário pelo Usuário ou o respectivo processamento, sem quaisquer ônus, penalidades ou encargos.

4. Condições para o uso ${getAppName(true)}

4.1. O ${getAppName(true)} poderá ser utilizado pelos Usuários que possuam uma conta no aplicativo ${getAppName()} BUSINESS, pelo qual serão registrados os recebíveis provenientes das transações de pagamento.
4.2. A qualquer momento, o ${getAppName()} poderá alterar a relação dos cartões aceitos.
4.3. O usuário reconhece e concorda que o ${getAppName()} poderá a qualquer momento atualizar, aprimorar, adicionar ou corrigir recursos tecnológicos, incluindo, mas não se limitando à atualização, limitação ou alteração do software.
4.4. O ${getAppName()} poderá suspender, bloquear e/ou cancelar, temporária ou definitivamente, qualquer usuário, independente de notificação prévia, caso conclua, a seu exclusivo critério, que alguma das transações realizadas pelo usuário com a utilização dos meios de pagamento oferecidos (i) caracterize alguma atividade proibida por lei ou por regulamentação aplicável; (ii) seja contrária ao disposto nos Termos de Uso do ${getAppName()}, ou ainda, caso o ${getAppName()} tome conhecimento ou suspeite de qualquer ilegitimidade, fraude ou qualquer outro ato que possa atentar contra a imagem da empresa, sem prejuízo de que sejam eventualmente adotadas as medidas judiciais ou extrajudiciais pertinentes.

5. Preço, pagamento e tarifas

5.1. Os custos, tarifas e formas de pagamentos das transações com uso do ${getAppName(true)} serão de ${percentage}% sobre o valor da transação realizada por cartão de crédito pelo pagador, ou 1% sobre o valor da transação caso o pagamento seja realizado por meio de transação pix. O Usuário está ciente que aderirá às condições comerciais dispostas a partir da primeira transação com o ${getAppName(true)}, e serão cobradas conforme condições publicadas neste termo. Fica facultada ao ${getAppName()} alterar as condições a qualquer tempo mediante simples comunicação pelos canais de atendimento.
5.2. O Usuário se obriga a remunerar o ${getAppName()} pelo uso do ${getAppName(true)}. Desta forma, o ${getAppName()} está autorizado a debitar dos recebíveis os custos e tarifas oriundos de cada movimentação.
5.3. O Usuário reconhece e concorda que os pagamentos efetuados por meio do ${getAppName(true)} serão disponibilizados no domicílio bancário indicado pelo Usuário no momento do cadastro no Portal.
5.4. Se a data prevista para o pagamento das transações recair em feriado, seja nacional ou regional, em dia de não funcionamento bancário na praça da sede do ${getAppName()}, ou na praça de compensação do domicílio bancário do Usuário, o pagamento será realizado no primeiro dia útil subsequente.
5.5. O ${getAppName()} poderá enviar periodicamente, à instituição financeira do domicílio bancário do Usuário, a agenda contendo a previsão de recebíveis decorrentes de transações do Usuário.
5.6. Nos casos em que se verificar a iliquidez, insolvência, pedido de recuperação judicial ou extrajudicial, estado pré-falimentar, encerramento de atividades ou qualquer outra hipótese em que ficar caracterizada a dificuldade do Usuário em cumprir suas obrigações contratuais e/ou legais, o ${getAppName()} reserva-se o direito de reter os créditos a ele devidos, a fim de garantir o cumprimento de suas obrigações previstas no presente Termo e a segurança do mercado de meios de pagamentos.
5.7. O Usuário terá o prazo máximo de 30 (trinta) dias, a contar da data prevista para a realização do pagamento pelo ${getAppName()}, para apontar qualquer eventual divergência em relação aos valores pagos e/ou debitados. Findo tal prazo, não caberá nenhuma outra reclamação por parte do Usuário, ocorrendo a quitação automática e definitiva quanto aos referidos valores.
5.7.1 Em casos excepcionais, o ${getAppName()}, a seu exclusivo critério, findo o prazo estipulado acima e respeitados os prazos de cobrança estipulados em Lei, verificar a existência de divergência nos créditos ou débitos apontados pelo Usuário e, conforme o caso, efetuar os créditos ou débitos em seu domicílio bancário.

6. Obrigações do Usuário

6.1. O usuário não poderá:
a) Usar o ${getAppName(true)} para finalidade diversa da descrita nestes Termos de Uso;
b) Violar estes Termos de uso e qualquer outro documento a ele vinculado disponibilizado pelo ${getAppName()}, bem como qualquer legislação municipal, estadual ou federal aplicável;
c) Disseminar ou tentar enviar vírus de computador ou qualquer outro código ou programa invasivo ou malicioso;
d) Copiar ou adaptar os códigos-fonte e objeto do ${getAppName(true)} ou de qualquer outro sistema disponibilizado pelo ${getAppName()} ou qualquer software a ele relacionado;
e) Fazer ou tentar fazer engenharia reversa, decompilação, modificação ou qualquer alteração nos códigos-fonte e objeto do ${getAppName(true)} ou qualquer software relacionado;
f) Interferir ou quebrar qualquer sistema de segurança do ${getAppName(true)}, especialmente aqueles com a finalidade de limitar e controlar o seu acesso e uso;
g) Utilizar o ${getAppName(true)} para aceitar pagamentos que infrinjam leis e regulamentos aplicáveis, bem como utilizem frames ou métodos para copiar (mirror) qualquer conteúdo que faça parte do ${getAppName(true)}, seja para utilização em sua intranet, para fins comerciais, internos ou qualquer outra utilização;
h) Utilizar o ${getAppName(true)} para aceitar transações de pagamento com cartões, cujo titular seja o próprio usuário ou sua empresa;
i) Armazenar, reproduzir, copiar dados fornecidos relativos ao cartão utilizado para processar pagamentos realizados no ${getAppName(true)};
j) Armazenar, usar ou transmitir informação pessoal de terceiros obtida por meio das operações processadas com o ${getAppName(true)}.

6.2. O usuário deverá:
a) Cumprir as leis e regulamentações aplicáveis, além de observar integralmente os Termos de Uso vigentes do ${getAppName(true)};
b) Responsabilizar-se integralmente pelo uso do ${getAppName(true)}, bem como por sua atividade comercial, independentemente dos bens ou serviços oferecidos;
c) Utilizar o ${getAppName(true)} de acordo com as regras do cartão, devendo saber que o ${getAppName()} não armazena os dados dos cartões dos clientes, exceto o dado básico para vinculação do cliente ao cartão;
d) Observar as leis e normativas aplicáveis a respeito de toda informação que receba em virtude do uso do ${getAppName(true)}, incluindo a observância, quando aplicável, das normas previstas no PCI-DSS, além do tomar todas as medidas de segurança necessárias para evitar ações fraudulentas;
e) Comunicar imediatamente o ${getAppName()} na hipótese de suspeita de fraude ou lavagem de dinheiro;
f) Estar ciente que o uso do ${getAppName(true)} pelo Usuário implica o cumprimento das regras e determinações pelas credenciadoras, bem como autorização automática para que o ${getAppName()}, sempre que julgarem necessário e inclusive por meio de participantes da indústria de cartões ou terceiros por eles credenciados: (i) verifiquem a regularidade da sua constituição societária, podendo para tanto solicitar documentos adicionais; e/ou (ii) avaliem o cumprimento das práticas de aceitação do ${getAppName()}.

7. Limitação de responsabilidade

7.1. O ${getAppName()} não tem e não terá qualquer responsabilidade pela exclusão, perda, erro de armazenamento, interrupções ou falha de transmissão. O Usuário declara que concorda em isentar o ${getAppName()}, suas empresas controladas, controladoras ou coligadas, bem como seus respectivos administradores, prepostos, funcionários e consultores de quaisquer responsabilidades, demandas, ações judiciais ou extrajudiciais, pedidos ou solicitações de terceiros decorrentes da utilização ${getAppName(true)}, incluindo despesas processuais e honorários advocatícios. Declara ainda concordar com o desconto em seus recebíveis para a reparação de eventuais prejuízos ou danos que venha causar ao ${getAppName()} e/ou terceiros.
7.2. Observada a limitação de responsabilidade prevista neste instrumento, com o qual o Usuário concorda em aceitá-la, a responsabilidade do ${getAppName()} por outros atos ou fatos causadores de danos limitar-se-ão aos danos diretos comprovadamente sofridos pelos Usuários por ato ilícito exclusivo do ${getAppName()}. O ${getAppName()} não poderá ser responsabilizados por danos indiretos, lucros cessantes, perda de dados ou de receitas decorrentes de falhas do ${getAppName(true)}.

8. Confidencialidade

8.1. O Usuário compromete-se a manter em absoluto sigilo e confidencialidade todas as Informações Confidenciais. No caso de sua eventual violação ou divulgação, inclusive por atos de seus funcionários ou terceiros, o Usuário será responsável pelo ressarcimento das perdas e danos ocasionados, incluindo danos emergentes, lucros cessantes, custas judiciais e honorários advocatícios.
8.2. O ${getAppName()} compromete-se a manter a confidencialidade de dados das transações efetuadas pelo Usuário, exceto quando solicitados por ordem judicial, exigidos por lei e/ou prestadores de serviço do ${getAppName()}. O ${getAppName()} poderá prestar às autoridades competentes, tais como o Ministério da Fazenda, o BACEN, a Receita Federal, as Secretarias da Fazenda Estaduais e Municipais, Comissões Parlamentares de Inquérito, Órgãos de Controle de Atividades Financeiras, Polícia Federal etc., todas as informações que forem solicitadas em relação ao Usuário ou quaisquer dados relativos às transações efetuadas pelo Usuário.
8.3. O Usuário, de forma irrevogável e irretratável, autoriza a ${getAppName()} e/ou as empresas pertencentes ao seu conglomerado a:
(I) trocarem entre si as informações confidenciais e demais informações, assim como consultar e/ou confirmar a exatidão das mesmas em websites e bancos de dados em geral;
(II) compartilhar as informações confidenciais e demais informações com os emissores, com o banco depositário do domicílio bancário e os instituidores de arranjo de pagamento;
(III) compartilhar informações confidenciais e demais informações com seus parceiros comerciais e prestadores de serviços, no brasil ou no exterior, para fins de avaliação de crédito, verificação e gestão de risco e fraude;
(IV) utilizar suas informações confidenciais e demais informações para formação de banco de dados, bem como sua divulgação a qualquer título, desde que de forma generalizada, agrupada com informações de outros Usuários e não individualizada; 
(V) compartilhar as informações confidenciais e demais informações com as autoridades reguladoras da indústria de meios de pagamento, com autoridades tributárias federais, estaduais e municipais, com agências de crédito oficiais ou privadas, por determinação em legislação, regulamentação de mercado, em medidas judiciais, entre outras; 
(VI) comunicar ao BACEN e ao COAF – Conselho de Controle de Atividades Financeiras as transações que possam estar configuradas no disposto na lei 9.613/98 e demais normas relativas à lavagem de dinheiro, incluindo as normas e políticas internas do ${getAppName()} nesse sentido; e
(VII) informar a órgãos de proteção de crédito, tais como SERASA e SPC, os dados relativos à falta de pagamento de obrigações assumidas pelo Usuário junto ao ${getAppName()}.

9. Prazo

9.1. Este Termo vigorará por tempo indeterminado, a contar da aceitação e inclusão do Usuário ao Sistema do ${getAppName(true)} por meio do credenciamento.

10. Disposições gerais

10.1. O credenciamento do Usuário acarreta aos Estabelecimentos a aceitação total e irrestrita deste Termo.
10.2. Direitos de Propriedade Intelectual do ${getAppName()}. Todos e quaisquer direitos de propriedade intelectual ou industrial decorrentes e/ou relativos aos serviços descritos nestes Termos, ao Site, ao Aplicativo, incluindo, sem limitação, direitos autorais patrimoniais, pertencem única e exclusivamente ao ${getAppName()}. Em nenhuma hipótese o estes Termos implicam transferência, no todo ou em parte, de qualquer direito de propriedade intelectual ou industrial pelo ${getAppName()} para o Estabelecimento. O Usuário expressamente reconhece e concorda que as regras serão aplicáveis, mutatis mutandis, a todo e qualquer software cujo uso venha a ser licenciado pelo ${getAppName()} ao Estabelecimento no âmbito da prestação dos serviços descritos nestes Termos, incluindo, sem limitação, o Portal.
10.3. Partes Autônomas e Independentes.  A relação jurídica estabelecida entre as Partes é de prestação de serviços, de modo que estes Termos não estabelecem relação de consumo, trabalho, terceirização de trabalho, representação comercial ou de qualquer outra natureza entre as Partes e entre as pessoas que colaboram com as Partes, sendo certo que as Partes são e permanecerão a todo tempo autônomas e independentes entre si.
10.4. Proibição de Cessão.  Os direitos e as obrigações do Usuário previstos nestes Termos não poderão ser cedidos ou de qualquer outra forma transferidos, no todo em parte, pelo Usuário a quaisquer terceiros sem a prévia concordância por escrito do ${getAppName()}.
10.5. Irrevogabilidade e Tolerância.  As relações jurídicas estabelecidas por estes Termos são celebradas em caráter irrevogável e irretratável, obrigando as Partes e seus sucessores, seja qual for o título da sucessão. A eventual tolerância por qualquer das Partes quanto ao inexato ou impontual cumprimento das obrigações da outra Parte valerá tão-somente de forma isolada, não constituindo renúncia ou novação de qualquer espécie.
10.6. Nulidade ou Ineficácia. Caso qualquer disposição destes Termos se torne nula ou ineficaz, a validade ou eficácia das disposições restantes não será afetada, permanecendo em pleno vigor e efeito e, em tal caso, as Partes entrarão em negociações de boa-fé visando a substituir a disposição ineficaz por outra que, tanto quanto possível e de forma razoável, atinja a finalidade e os efeitos desejados.
10.7. Totalidade dos Entendimentos. As disposições contidas no Contrato e nestes Termos e Condições representam a totalidade dos entendimentos mantidos entre as Partes relativamente aos assuntos de que ele trata, superando todos e quaisquer entendimentos anteriores, verbais ou escritos, havidos entre as Partes, consubstanciando-se na declaração final de suas vontades.
10.8. Comunicações. As comunicações citadas nestes Termos ou feitas com relação a eles deverão ser feitas por escrito em qualquer canal disponibilizado pelo ${getAppName()}, inclusive por e-mail.
10.9. Demais condições operacionais e/ou cadastrais para execução do objeto do presente Termo estarão disponíveis no Portal e serão aplicáveis a partir da adesão ao presente Termo.
10.10. Este instrumento será regido e interpretado de acordo com a legislação brasileira, sendo eleito o Foro da Comarca de Ribeirão Preto, Estado de São Paulo, para questões a ele relativas, com renúncia expressa a qualquer outro.
10.11. O Usuário concorda que o ${getAppName()} poderá alterar estes Termos de Uso e demais documentos a ele atrelados unilateralmente com simples notificação no site do ${getAppName()}. Cabe ao Usuário visitar estes Termos de Uso de tempos em tempos e, se não concordar com alguma disposição, poderá, a seu critério, deixar de usar o ${getAppName(true)}.
`;
	return terms;
};

/******************************************************
 * Mapeia erros do Firebase (códigos) para mensagens PT
 ******************************************************/
export const fireBaseErrors = (code) => {
	const params = {
		"auth/app-deleted": "O banco de dados não foi localizado.",
		"auth/expired-action-code": "O código da ação ou link expirou.",
		"auth/invalid-action-code":
			"O código da ação é inválido. Isso pode acontecer se o código estiver malformado ou já tiver sido usado.",
		"auth/user-disabled": "O usuário correspondente à credencial fornecida foi desativado.",
		"auth/user-not-found": "O usuário não corresponde a nenhuma credencial.",
		"auth/weak-password": "A senha é muito fraca.",
		"auth/email-already-in-use": "Já existe uma conta com o endereço de email fornecido.",
		"auth/invalid-email": "O endereço de e-mail não é válido.",
		"auth/operation-not-allowed": "O tipo de conta correspondente a esta credencial ainda não está ativado.",
		"auth/account-exists-with-different-credential": "E-mail já associado a outra conta.",
		"auth/auth-domain-config-required": "A configuração para autenticação não foi fornecida.",
		"auth/credential-already-in-use": "Já existe uma conta para esta credencial.",
		"auth/operation-not-supported-in-this-environment":
			"Esta operação não é suportada no ambiente que está sendo executada. Verifique se deve ser http ou https.",
		"auth/timeout": "Excedido o tempo de resposta. O domínio pode não estar autorizado para realizar operações.",
		"auth/missing-android-pkg-name": "Deve ser fornecido um nome de pacote para instalação do aplicativo Android.",
		"auth/missing-continue-uri": "A próxima URL deve ser fornecida na solicitação.",
		"auth/missing-ios-bundle-id": "Deve ser fornecido um nome de pacote para instalação do aplicativo iOS.",
		"auth/invalid-continue-uri": "A próxima URL fornecida na solicitação é inválida.",
		"auth/unauthorized-continue-uri": "O domínio da próxima URL não está na lista de autorizações.",
		"auth/invalid-dynamic-link-domain":
			"O domínio de link dinâmico fornecido não está autorizado ou configurado no projeto atual.",
		"auth/argument-error": "Verifique a configuração de link para o aplicativo.",
		"auth/invalid-persistence-type": "O tipo especificado para a persistência dos dados é inválido.",
		"auth/unsupported-persistence-type":
			"O ambiente atual não suporta o tipo especificado para persistência dos dados.",
		"auth/invalid-credential": "A credencial expirou ou está mal formada.",
		"auth/wrong-password": "Senha incorreta.",
		"auth/invalid-verification-code":
			"O código informado está incorreto. Por favor, verifique o SMS e tente novamente.",
		"auth/invalid-verification-id": "O ID de verificação da credencial não é válido.",
		"auth/custom-token-mismatch": "O token está diferente do padrão solicitado.",
		"auth/invalid-custom-token": "O token fornecido não é válido.",
		"auth/captcha-check-failed": "O token de resposta do reCAPTCHA não é válido, expirou ou o domínio não é permitido.",
		"auth/invalid-phone-number": "O número de telefone está em um formato inválido.",
		"auth/missing-phone-number": "O número de telefone é requerido.",
		"auth/quota-exceeded": "A cota de envios SMS foi excedida. Tente novamente mais tarde.",
		"auth/cancelled-popup-request": "Somente uma solicitação de janela pop-up é permitida de uma só vez.",
		"auth/popup-blocked": "A janela pop-up foi bloqueada pelo navegador.",
		"auth/popup-closed-by-user": "A janela pop-up foi fechada pelo usuário sem concluir o login.",
		"auth/unauthorized-domain": "O domínio do aplicativo não está autorizado para realizar operações.",
		"auth/invalid-user-token": "O usuário atual não foi identificado.",
		"auth/user-token-expired": "O token do usuário atual expirou.",
		"auth/null-user": "O usuário atual é nulo.",
		"auth/app-not-authorized": "Aplicação não autorizada para autenticar com a chave informada.",
		"auth/invalid-api-key": "A chave da API fornecida é inválida.",
		"auth/network-request-failed": "Falha de conexão com a rede.",
		"auth/requires-recent-login": "O último horário de acesso do usuário não atende ao limite de segurança.",
		"auth/too-many-requests":
			"Você acessou esta conta várias vezes em um curto período, tente novamente em pelo menos 3 horas.",
		"auth/web-storage-unsupported": "O navegador não suporta armazenamento ou este recurso foi desativado.",
		"auth/invalid-claims": "Os atributos de cadastro personalizado são inválidos.",
		"auth/claims-too-large": "O tamanho da requisição excede o máximo permitido de 1 Megabyte.",
		"auth/id-token-expired": "O token informado expirou.",
		"auth/id-token-revoked": "O token informado perdeu a validade.",
		"auth/invalid-argument": "Um argumento inválido foi fornecido a um método.",
		"auth/invalid-creation-time": "O horário da criação precisa ser uma data UTC válida.",
		"auth/invalid-disabled-field": "A propriedade para usuário desabilitado é inválida.",
		"auth/invalid-display-name": "O nome do usuário é inválido.",
		"auth/invalid-email-verified": "O e-mail é inválido.",
		"auth/invalid-hash-algorithm": "O algoritmo de HASH não é uma criptografia compatível.",
		"auth/invalid-hash-block-size": "O tamanho do bloco de HASH não é válido.",
		"auth/invalid-hash-derived-key-length": "O tamanho da chave derivada do HASH não é válido.",
		"auth/invalid-hash-key": "A chave de HASH precisa ter um buffer de byte válido.",
		"auth/invalid-hash-memory-cost": "O custo da memória HASH não é válido.",
		"auth/invalid-hash-parallelization": "O carregamento em paralelo do HASH não é válido.",
		"auth/invalid-hash-rounds": "O arredondamento de HASH não é válido.",
		"auth/invalid-hash-salt-separator": "O campo do separador de SALT precisa ser um buffer de byte válido.",
		"auth/invalid-id-token": "O código do token informado não é válido.",
		"auth/invalid-last-sign-in-time": "O último horário de login precisa ser uma data UTC válida.",
		"auth/invalid-page-token": "A próxima URL fornecida na solicitação é inválida.",
		"auth/invalid-password": "A senha é inválida, precisa ter pelo menos 6 caracteres.",
		"auth/invalid-password-hash": "O HASH da senha não é válido.",
		"auth/invalid-password-salt": "O SALT da senha não é válido.",
		"auth/invalid-photo-url": "A URL da foto de usuário é inválida.",
		"auth/invalid-provider-id": "O identificador de provedor não é compatível.",
		"auth/invalid-session-cookie-duration":
			"A duração do COOKIE da sessão precisa ser um número válido em milissegundos (entre 5 minutos e 2 semanas).",
		"auth/invalid-uid": "O identificador fornecido deve ter no máximo 128 caracteres.",
		"auth/invalid-user-import": "O registro do usuário a ser importado não é válido.",
		"auth/invalid-provider-data": "O provedor de dados não é válido.",
		"auth/maximum-user-count-exceeded": "O número máximo de usuários a serem importados foi excedido.",
		"auth/missing-hash-algorithm":
			"É necessário fornecer o algoritmo de geração de HASH e seus parâmetros para importar usuários.",
		"auth/missing-uid": "Um identificador é necessário para a operação atual.",
		"auth/reserved-claims": "Uma ou mais propriedades personalizadas fornecidas usaram palavras reservadas.",
		"auth/session-cookie-revoked": "O COOKIE da sessão perdeu a validade.",
		"auth/uid-alread-exists": "O identificador fornecido já está em uso.",
		"auth/email-already-exists": "O e-mail fornecido já está em uso.",
		"auth/phone-number-already-exists": "O telefone fornecido já está em uso.",
		"auth/project-not-found": "Nenhum projeto foi encontrado.",
		"auth/insufficient-permission": "A credencial utilizada não tem permissão para acessar o recurso solicitado.",
		"auth/internal-error": "O servidor de autenticação encontrou um erro inesperado ao tentar processar a solicitação.",
	};

	return params[code] || "Ocorreu um erro desconhecido. code: " + code;
};

/********************************************************************
 * Máscara de telefone BR, permitindo apagar gradualmente a formatação
 ********************************************************************/
export const telephoneMask = (e, maxLength = 15) => {
	e.target.maxLength = maxLength;
	let value = e.target.value.replace(/\D/g, "");
	// Remove zero inicial
	value = value.replace(/^0/, "");

	// Se usuário apagou tudo
	if (!value) {
		e.target.value = "";
		return;
	}

	// Monta step-by-step
	if (value.length < 3) {
		e.target.value = value;
	} else if (value.length <= 6) {
		e.target.value = `(${value.slice(0, 2)}) ${value.slice(2)}`;
	} else if (value.length <= 10) {
		e.target.value = `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6)}`;
	} else {
		e.target.value = `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
	}
};

/********************************************************************
 * Máscara de telefone Portugal
 ********************************************************************/
export const telephoneMaskPOR = (e, maxLength = 9) => {
	e.target.maxLength = maxLength;
	let value = e.target.value.replace(/\D/g, "");
	value = value.replace(/^0/, "");

	// Se apagou tudo
	if (!value) {
		e.target.value = "";
		return;
	}

	if (value.length <= 3) {
		e.target.value = value;
	} else if (value.length <= 6) {
		e.target.value = `${value.slice(0, 3)}-${value.slice(3)}`;
	} else {
		e.target.value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 9)}`;
	}
};

/********************************************************************
 * Máscara de telefone EUA: 999-999-9999
 ********************************************************************/
export const telephoneMaskEUA = (e, maxLength = 12) => {
	e.target.maxLength = maxLength;
	const value = e.target.value.replace(/\D/g, "");

	if (!value) {
		e.target.value = "";
		return;
	}

	if (value.length <= 3) {
		e.target.value = value;
	} else if (value.length <= 6) {
		e.target.value = `${value.slice(0, 3)}-${value.slice(3)}`;
	} else {
		e.target.value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
	}
};

/********************************************************************
 * Máscara Inglaterra (muito parecido com EUA, mas adaptável se preciso)
 ********************************************************************/
export const telephoneMaskING = (e, maxLength = 12) => {
	e.target.maxLength = maxLength;
	const value = e.target.value.replace(/\D/g, "");

	if (!value) {
		e.target.value = "";
		return;
	}

	if (value.length <= 3) {
		e.target.value = value;
	} else if (value.length <= 6) {
		e.target.value = `${value.slice(0, 3)}-${value.slice(3)}`;
	} else {
		e.target.value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
	}
};

/********************************************************************
 * Máscara Angola
 ********************************************************************/
export const telephoneMaskANG = (e, maxLength = 11) => {
	e.target.maxLength = maxLength;
	const value = e.target.value.replace(/\D/g, "");

	if (!value) {
		e.target.value = "";
		return;
	}

	if (value.length <= 3) {
		e.target.value = value;
	} else if (value.length <= 6) {
		e.target.value = `${value.slice(0, 3)}-${value.slice(3)}`;
	} else {
		e.target.value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
	}
};

/********************************************************************
 * Máscara Espanha: 000-000-000
 ********************************************************************/
export const telephoneMaskESP = (e, maxLength = 11) => {
	e.target.maxLength = maxLength;
	const value = e.target.value.replace(/\D/g, "");

	if (!value) {
		e.target.value = "";
		return;
	}

	if (value.length <= 3) {
		e.target.value = value;
	} else if (value.length <= 6) {
		e.target.value = `${value.slice(0, 3)}-${value.slice(3)}`;
	} else {
		e.target.value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 9)}`;
	}
};

/********************************************************************
 * Máscara Índia: 00000-00000
 ********************************************************************/
export const telephoneMaskIND = (e, maxLength = 11) => {
	e.target.maxLength = maxLength;
	const value = e.target.value.replace(/\D/g, "");

	if (!value) {
		e.target.value = "";
		return;
	}

	if (value.length <= 4) {
		e.target.value = value;
	} else if (value.length <= 6) {
		e.target.value = `${value.slice(0, 4)}-${value.slice(4)}`;
	} else {
		e.target.value = `${value.slice(0, 5)}-${value.slice(5, 11)}`;
	}
};

/********************************************************************
 * Máscara França
 ********************************************************************/
export const telephoneMaskFRA = (e, maxLength = 13) => {
	e.target.maxLength = maxLength;
	let value = e.target.value.replace(/\D/g, "");
	value = value.replace(/^0/, "");

	if (!value) {
		e.target.value = "";
		return;
	}

	// França costuma ter: 9 dígitos + 1 inicial ou 10 dígitos
	// Adapte aqui conforme a formatação local.
	if (value.length <= 2) {
		// Mostra só
		e.target.value = value;
	} else if (value.length <= 5) {
		e.target.value = `${value.slice(0, 1)}-${value.slice(1)}`;
	} else if (value.length <= 8) {
		e.target.value = `${value.slice(0, 1)}-${value.slice(1, 4)}-${value.slice(4)}`;
	} else if (value.length <= 11) {
		e.target.value = `${value.slice(0, 1)}-${value.slice(1, 4)}-${value.slice(4, 7)}-${value.slice(7)}`;
	} else {
		e.target.value = `${value.slice(0, 1)}-${value.slice(1, 4)}-${value.slice(4, 7)}-${value.slice(
			7,
			10
		)}-${value.slice(10)}`;
	}
};

/****************************************************
 * Limita o campo a apenas dígitos e tamanho (default)
 ****************************************************/
export const numberMask = (e, maxLength = 1) => {
	e.target.maxLength = maxLength;
	const value = e.target.value.replace(/\D/g, "");
	e.target.value = value;
};

/*********************************************
 * Remove todos os caracteres não-numéricos
 *********************************************/
export const trimTelephoneNumber = (number) => {
	return number.replace(/\D/g, "");
};

export const findDdiNumber = (phone) => {
	// validate if have + in the beginning
	let _phone = phone;
	if (!phone.startsWith("+")) _phone = "+" + phone;
	if (_phone.startsWith("+55")) {
		return { id: 1, phone: _phone.substring(2), ddi: "+55" };
	} else if (_phone.startsWith("+351")) {
		return { id: 2, phone: _phone.substring(3), ddi: "+351" };
	} else if (_phone.startsWith("+1")) {
		return { id: 3, phone: _phone.substring(1), ddi: "+1" };
	} else if (_phone.startsWith("+44")) {
		return { id: 4, phone: _phone.substring(2), ddi: "+44" };
	} else if (_phone.startsWith("+244")) {
		return { id: 5, phone: _phone.substring(3), ddi: "+244" };
	} else if (_phone.startsWith("+33")) {
		return { id: 6, phone: _phone.substring(2), ddi: "+33" };
	} else if (_phone.startsWith("+34")) {
		return { id: 7, phone: _phone.substring(2), ddi: "+34" };
	} else if (_phone.startsWith("+91")) {
		return { id: 8, phone: _phone.substring(2), ddi: "+91" };
	}
};

export const getDdiNumber = (phoneDdi, isUsingPhoneNumber = false) => {
	if (isUsingPhoneNumber) {
		const ddiMatch = phoneDdi.match(/^\+(\d{2,3})/);
		return ddiMatch ? ddiMatch[1] : "";
	}

	if (phoneDdi === 1) {
		return "55";
	} else if (phoneDdi === 2) {
		return "351";
	} else if (phoneDdi === 3) {
		return "1";
	} else if (phoneDdi === 4) {
		return "44";
	} else if (phoneDdi === 5) {
		return "244";
	} else if (phoneDdi === 6) {
		return "33";
	} else if (phoneDdi === 7) {
		return "34";
	} else if (phoneDdi === 8) {
		return "91";
	}

	return "";
};

export const handlePhoneChange = (e, ddi) => {
	switch (ddi) {
		case 1:
			telephoneMask(e);
			break;
		case 2:
			telephoneMaskPOR(e);
			break;
		case 3:
			telephoneMaskEUA(e);
			break;
		case 4:
			telephoneMaskING(e);
			break;
		case 5:
			telephoneMaskANG(e);
			break;
		case 6:
			telephoneMaskFRA(e);
			break;
		case 7:
			telephoneMaskESP(e);
			break;
		case 8:
			telephoneMaskIND(e);
			break;
		default:
			numberMask(e, 20);
			break;
	}
	return e.target.value;
};

/****************************************************
 * Retorna número aleatório de 0 até (max-1)
 ****************************************************/
export const randomInt = (max) => Math.floor(Math.random() * max);

export const telephoneNumberMask = (number) => {
	let formated = "";

	if (!number) {
		return number;
	}

	for (var i = 1; i < number.length; i++) {
		if (i === 1) {
			formated += "(";
		}
		if (i === 3) {
			formated += ") ";
		}
		if (i === 7) {
			formated += "-";
		}
		formated += number.charAt(i);
	}

	return formated;
};
